export const seo = {
  url: 'transport-branze',
  title: {
    pl: 'Transport dla branż | Transport w przemyśle',
    en: 'Transport for industries| Transport in industry branches',
  },
  desc: {
    pl: 'Transport dopasowany do specyfiki danej branży. Realizacja transportu z dbałością o detale i dopasowanie do konkretnych produktów.',
    en: 'Transport tailored to the specifics of a given industry. Carrying out transport with attention to detail and matching to specific products.',
  },
  keywords: ['omida', 'logistics', 'transport', 'branże', 'przemysł'],
}

export const intro = {
  title: {
    pl: 'Transport dla Branż',
    en: 'Transport Branże',
  },
  desc: {
    pl: 'Transport dopasowany do specyfiki danej branży. Realizacja transportu z dbałością o detale i dopasowanie do konkretnych produktów.',
    en: 'Transport tailored to the specifics of a given industry. Carrying out transport with attention to detail and matching to specific products.',
  },
  button: {
    text: {
      pl: 'Czytaj więcej',
      en: 'Read more',
    },
    action: 'SCROLL',
  },
}

export const main = {
  title: {
    pl: 'Transport Dedykowany dla Twojej branży',
    en: 'Transport dedicated to your industry',
  },
  texts: [
    {
      pl: 'Szukasz transportu dopasowanego do specyfiki danej gałęzi przemysłu? Nasz doświadczony zespół zrealizuje Twój transport z dbałością o detale i dopasowanie do Twojego biznesu i poszczególnych produktów. Wybierz Omida VLS - doświadczonego partnera logistycznego.',
      en: 'Are you looking for transport tailored to the specifics of a given industry branch? Our experienced team will carry out your transport with attention to detail and adaptation to your business and individual products. Choose Omida VLS - an experienced logistics partner.',
    },
  ],
}

export const buttonBack = {
  text: {
    pl: 'Powrót do transportu',
    en: 'Back to transport',
  },
  link: '/transport/',
}
